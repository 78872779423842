<template>
  <div>

<!--    <contact2-->
<!--      :contact.sync="contactTest"-->
<!--      :is-initial-contact-company="true"-->
<!--      :is-individual-only="true"-->
<!--      :with-me="false"-->
<!--    />-->

    <base-template
        title="Tickets"
        itemView="list"
        :columns="columns"
        :filters="filters"
        :items="tickets"
        :context="ticketsContext"
        :isActionColumnEnabled="true"

        @fetch="fetchTickets"
        @addItem="ticketModalShow=true"
        @selectItem="viewTicket"
    >
      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native="viewTicket(item)"
              v-b-tooltip.hover.left="capitalize($t('view'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Next step-->
          <button-next
              v-if="$can('manage', 'all') && getUserData().id == 1 && item.status != 'Done'"
              @click.native="nextStep(item)"
              v-b-tooltip.hover.left="capitalize($t('NextStep'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Validate correction-->
          <button-validate
              v-if="item.status == 'Done' && item.isCorrectionValidated != true"
              @click.native="validateCorrection(item)"
              v-b-tooltip.hover.left="capitalize($t('validateCorrection'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Un-Validate correction-->
          <button-exclamation-triangle
              v-if="item.status == 'Done' && item.isCorrectionValidated == null"
              @click.native="unValidateCorrection(item)"
              v-b-tooltip.hover.left="capitalize($t('unValidateCorrection'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Cancel-->
          <button-delete
              v-if="$can('manage', 'all') && getUserData().id == 1 && item.status != 'Done' && item.status != 'Cancelled'"
              @click.native.stop="cancelTicket(item)"
              v-b-tooltip.hover.left="capitalize($t('cancel'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit-->
          <button-edit
              v-if="item.status == 'New'"
              @click.native="selectTicket(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="
              ($can('manage', 'all') && item.status == 'New')||
              (!$can('manage', 'all') && item.status == 'New')"
              @click.native.stop="deleteTicketAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


        </div>
      </template>
    </base-template>


    <!-- modal ticket -->
    <modal-ticket
        :ticket.sync="currentTicket"
        :title="ticketModalTitle"
        :isOpen.sync="ticketModalShow"
        :isView="isViewModal"
        @submitValidated="submitValidatedTicketLocal"
    />

  </div>
</template>

<script>
import { mixinTickets } from './mixinTickets'

export default {
  mixins: [mixinTickets]
}
</script>

<style lang="scss">

</style>