<template>
  <generic-card
      :byContent="true"
  >
    <template #content>
      <content-document
          @click="$emit('click')"
          @download="$emit('download')"
          @edit="$emit('edit')"
          @delete="$emit('delete')"
          @toRoute="$emit('toRoute')"
          :document="document"
          :isParentMethodPriority="true"
          :is-deletable="isDeletable"
          :is-linkable="isLinkable"
          :small="small"
      />
    </template>

  </generic-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericCard from './Card'
import ContentDocument from './components/DocumentContent'

export default {
  components: {
    GenericCard,
    ContentDocument
  },
  props: {
    document: {
      type: Object,
      default: () => { }
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isLinkable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
    //   extension: {
    //     type: String,
    //     default: ''
    //   },
    //   name: {
    //     type: String,
    //     default: ''
    //   },
    //   url: {
    //     type: String,
    //     default: ''
    //   }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconLocal = computed(() => {
      if (props.document.extension == 'pdf') return 'file-pdf'
      if (['jpg', 'png'].includes(props.document.extension)) return 'file-image'
      else return 'file'
    })

    const libraryLocal = computed(() => {
      if (props.document.extension == 'pdf') return 'far'
      else return 'fas'
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = (hovered) => {
      isHovered.value = hovered
    }

    const download = () => {
      if ('url' in props.document && props.document.url != null) {
        let link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.href = props.document.url
        link.click()
      } else {
        emit('download')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,

      // Computed
      iconLocal,
      libraryLocal,

      // Methods
      handleHover,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
    // console.log(this.document)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>