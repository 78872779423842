<template>
  <div>

    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon mb-2"
        variant="outline-primary"
        @click="employeeModalShow=true"
    >
      <icon icon="plus"/>
    </b-button>


    <b-row>
      <b-col
          v-for="(employee, index) in employees"
          :key="index"
          md="6"
      >
        <employee-card
            :employee="employee"
            class="cursor-pointer"
            @click="selectEmployee(employee, index)"
            @deleteEmployee="deleteEmployee(index)"
        />
      </b-col>
    </b-row>


    <b-modal
        :title="employeeModalTitle"
        v-model="employeeModalShow"
        size="lg"
        scrollable
        :hide-footer="hideFooter"
        :ok-title="capitalize($t('validate'))"
        :cancel-title="capitalize($t('cancel'))"
        @hide="tryCloseEmployeeModal"
        @hidden="handleCancel"
        @ok="handleOk"
    >
      <validation-observer
          ref="observerRef"
          #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(employeeFormValidated)">
          <user-form :user="currentEmployee"
                     :displayCompany="false"/>

          <button ref="submitEmployeeForm"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'

import useAPI from '../../../../utils/useAPI'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import UserForm from '@/components/form/User'
import EmployeeCard from '@/components/card/Employee'

export default {
  directives: {
    Ripple
  },
  components: {
    ValidationObserver,
    UserForm,
    EmployeeCard
  },
  props: {
    employees: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const employeeModalShow = ref(false)
    const employeeModalTitle = ref(i18n.t('NewEmployee'))
    const currentEmployee = ref({})
    const selectedEmployeeInit = ref(null)
    const selectedEmployeeIndex = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(employeeModalShow, () => {
      if (employeeModalShow.value == false) {
        if (selectedEmployeeInit.value != null) {
          // Undo changes
          props.employees.splice(selectedEmployeeIndex.value, 1, selectedEmployeeInit.value)
        }

        resetEmployee()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetEmployee = () => {
      currentEmployee.value = JSON.parse(JSON.stringify({
        roles: []
      }))
      selectedEmployeeInit.value = JSON.parse(JSON.stringify(null))
      selectedEmployeeIndex.value = JSON.parse(JSON.stringify(null))
    }

    const selectEmployee = (employee, employeeIndex) => {
      selectedEmployeeInit.value = JSON.parse(JSON.stringify(employee))
      selectedEmployeeIndex.value = JSON.parse(JSON.stringify(employeeIndex))

      currentEmployee.value = employee
      employeeModalShow.value = true
    }

    const hasSameUserWithoutId = (user) => {
      return props.employees.filter(employee => {
        return !('id' in employee) &&
            user.name == employee.name &&
            user.firstName == employee.firstName &&
            user.phone == employee.phone &&
            user.email == employee.email
      }).length
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetEmployee()

    return {
      capitalize,

      observerRef,

      // Computed
      hideFooter,

      hasSameUserWithoutId,
      employeeModalShow,
      employeeModalTitle,
      currentEmployee,
      selectedEmployeeInit,
      selectEmployee,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()

      const isValid = await this.$refs.observerRef.validate()
      if (isValid) {
        this.employeeFormValidated()
      }
    },
    employeeFormValidated () {
      // console.log('Employee form validated !')
      // console.log(this.currentEmployee)

      if (!('id' in this.currentEmployee)) {
        if (this.hasSameUserWithoutId(this.currentEmployee) == 0) {
          // New employee (and not edit not saved employee)
          this.employees.splice(this.employees.length, 0, this.currentEmployee)
        }
        this.selectedEmployeeInit = JSON.parse(JSON.stringify(null))

        this.$nextTick(() => this.employeeModalShow = false)
      } else {
        // console.log("1")
        this.selectedEmployeeInit = JSON.parse(JSON.stringify(null))

        this.$nextTick(() => {this.employeeModalShow = false})

      }
    },
    tryCloseEmployeeModal (modalEvent) {
      if (modalEvent.trigger == 'backdrop') {
        modalEvent.preventDefault()
        this.$refs.submitEmployeeForm.click()
      }
    },
    deleteEmployee (index) {
      this.employees.splice(index, 1)
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>