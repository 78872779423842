<template>
  <generic-content
      v-b-tooltip.hover.bottom="tooltip"
      :isDownloadable="true"
      :isLinkable="isLinkable"
      :isEditable="isEditable"
      :isDeletable="isDeletable"

      @click="isParentMethodPriority?$emit('click'):click()"
      @download="isParentMethodPriority?$emit('download'):download()"
      @toRoute="$emit('toRoute')"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
  >
    <template #iconContent>
      <div
          v-if="document.mimeType == null || !document.mimeType.includes('image')"
      >
        <b-avatar
            rounded
            :size="small?21:42"
            variant="light-primary"
        >
          <icon
              :icon="iconLocal"
              size="lg"
              :library="libraryLocal"
          />
        </b-avatar>
      </div>
      <div
          v-else
          class="m-auto"
          style="max-width: 42px; max-height: 42px;"
      >
        <b-img
            :src="document.url"
            fluid
            :alt="document.name"
        />
      </div>
    </template>

    <template #bodyContent>
      <h6
          class="transaction-title text-truncate"
          :class="{'my-auto': small}"
      >
        {{ document.name }}
      </h6>
      <div v-if="!small" class="text-truncate">
        <small>{{ document.fileName ? document.fileName : document.name }}.{{ document.extension }}</small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    document: {
      type: Object,
      default: () => { }
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isLinkable: {
      type: Boolean,
      default: false
    },
    isParentMethodPriority: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconLocal = computed(() => {
      if (props.document.extension.toLowerCase() == 'pdf') return 'file-pdf'
      else if (['jpg', 'png'].includes(props.document.extension.toLowerCase())) return 'file-image'
      else if (props.document.extension.toLowerCase() == 'xlsx') return 'file-excel'
      else return 'file'
    })

    const libraryLocal = computed(() => {
      if (props.document.extension.toLowerCase() == 'pdf') return 'far'
      else return 'fas'
    })

    const tooltip = computed(() => {
      // console.log(JSON.parse(JSON.stringify(props.document)))
      return props.document.name + '.' + props.document.extension
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const click = () => {
      commonDownload()
    }
    const download = () => {
      commonDownload()
    }

    const commonDownload = () => {
      if ('url' in props.document && props.document.url != null) {
        let link = document.createElement('a')
        link.setAttribute('target', '_blank')
        link.href = props.document.url
        link.click()
      } else {
        emit('download')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      iconLocal,
      libraryLocal,
      tooltip,

      // Methods
      click,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>