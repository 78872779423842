import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { useSearchFilter } from './useSearchFilter'

import useAPI from '../../../utils/useAPI'
import i18n from '../../../libs/i18n'
import Ripple from 'vue-ripple-directive'
import FieldDate from '../../input/Date'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {
    FieldDate
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 25
    },
    context: {
      type: Object,
      default: () => {}
    },
    filters: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    selectedFilters: {
      type: Array,
      default: () => []
    },
    query: {
      type: String,
      default: ''
    },
    selectedView: {
      type: String,
      default: 'grid'
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    },

  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const { itemViewOptions } = useSearchFilter()
    const listFilters = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { isLoading } = useAPI()

    const itemPerPagesDisplayed = computed(() => {
      let startNumber = props.currentPage * props.perPage - (props.perPage - 1)
      let endNumber = (props.context.total_results < props.perPage ? props.context.total_results : (props.perPage * props.currentPage > props.context.total_results ? props.context.total_results : props.perPage * props.currentPage))
      let total = props.context.total_results ? props.context.total_results : endNumber

      return startNumber.toString() + ' - ' + endNumber.toString() + ' ' + i18n.t('on') + ' ' + total.toString()
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { toogleItem, getLast3months } = useSearchFilter()

    const formatListFilters = (filters, columns) => {
      let formatedListFilters = []

      filters.forEach(s => {
        formatedListFilters = formatedListFilters.concat(s)

        if (s.selected) {
          emit('selectFilter', s)
          // selectFilter(s)
        }
      })

      if (columns.some(c => c.cell && c.cell.isDate)) {
        formatedListFilters.push({
          'divider': true
        })

        columns.filter(c => c.cell && c.cell.isDate).forEach(c => {
          let children = getLast3months(c)
          let start = moment().subtract(1, 'months').startOf('month')
          let end = moment().endOf('month')

          // if (props.showPastYearsFilter) {
          children = children.concat([{ 'divider': true }])
          // }

          children.push({
            text: c.display,
            key: '_date_custom_filter',
            searchCriteria: [{
              column_name: c.key,
              comparator: 'range',
              search_text_0: start.format('YYYY-MM-DD'),
              search_text_1: end.format('YYYY-MM-DD')
            }]
          })
          children = children.concat([{ 'divider': true }])

          formatedListFilters.push({
            text: c.display,
            key: c.key,
            showSubMenu: false,
            children: children
          })
        })
      }

      // console.log( formatedListFilters)
      return formatedListFilters
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    listFilters.value = formatListFilters(props.filters, props.columns)


    return {
      // Components
      capitalize,
      currency,

      // Data
      itemViewOptions,
      listFilters,

      // Computed
      isLoading,
      itemPerPagesDisplayed,

      // Methods
      toogleItem,
      // selectFilter,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}