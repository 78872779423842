<template>
  <generic-button
      @click="$emit('click')"
      :variant="variant"
      :title="withText?'exclamation':''"
      :icon="withIcon?'exclamation-triangle':''"
      :size="size"
      :block="block"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import GenericButton from './Button'

export default {
  components: {
    GenericButton
  },
  props: {
    withIcon: {
      type: Boolean,
      default: false
    },
    withText: {
      type: Boolean,
      default: true
    },
    withBorder: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const variant = computed(() => {
      if (props.withBorder) {
        return 'outline-danger'
      } else {
        return 'flat-danger'
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      variant,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>