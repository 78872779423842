<template>
  <div class="w-100">

    <!--    <v-select-->
    <!--        v-model="localModel"-->
    <!--        :options="listContacts"-->
    <!--        label="_display"-->
    <!--        :getOptionKey="getOptionKey"-->
    <!--        :multiple="multiple"-->
    <!--        @input="newSelectedContact"-->
    <!--    />-->
    <div class="d-flex justify-content-between">
      <field-select
          :name="name"
          :model.sync="localModel"
          :options="listContacts"
          label="_display"
          :isRequired="isRequired"
          :allowAdd="!displayOnly"
          addText="AddContact"
          :listIndex="listIndex"
          :display-label="displayLabel"
          @addCallback="selectContact(true)"
          @input="newSelectedContact"
          class="w-100"
          :placeholder="placeholder?placeholder:name"
          :disabled="isDisabled"
      />

      <div
          class="mt-2 ml-1"
          v-if="!displayOnly"
      >
        <button-external-link
            :withBorder="false"
            @click="selectContact(false)"
            :disabled="localModel == null"
            style="margin-top: -26px"
        />
      </div>

    </div>


    <!-- modal ticket -->
    <modal-contact
        :contact.sync="currentContact"
        :title="isContactEdit?$t('EditContact'):$t('NewContact')"
        :isOpen.sync="contactModalShow"
        :is-contact-edit="isContactEdit"
        @submitValidatedContact="submitValidatedContactLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { isObject, getUserData } from '../../utils/utils'
import { useContacts } from '../../views/contacts/useContacts'

import vSelect from 'vue-select'
import FieldSelect from './Select2'
import useAPI from '../../utils/useAPI'
import modalContact from '../prompt/Contact'
import ButtonExternalLink from '../button/ExternalLink'
import store from '../../store'

export default {
  components: {
    vSelect,
    FieldSelect,
    modalContact,
    ButtonExternalLink
  },
  props: {
    name: {
      type: String,
      default: 'contact'
    },
    contact: {},
    isInitialContactCompany: {
      type: Boolean,
      required: true
    },
    isCompanyOnly: {
      type: Boolean,
      default: false
    },
    isIndividualOnly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    withMe: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    displayOnly: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref()
    const currentContact = ref({})
    const isContactEdit = ref(false)
    const contactModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteContacts } = useAPI()

    const listContacts = computed(() => {
      if (props.isCompanyOnly) {
        return autocompleteContacts.value.filter(c => c._type == 'company')
      } else if (props.isIndividualOnly) {
        if (props.options.length) {
          return props.withMe ? removeMe(props.options):props.options
        } else {
          return props.withMe ? autocompleteContacts.value.filter(c => c._type == 'user') : removeMe(autocompleteContacts.value.filter(c => c._type == 'user'))
        }
      } else {
        return props.withMe ? autocompleteContacts.value : removeMe(autocompleteContacts.value)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => props.contact, val => {
    //   // Reste bloqué sur une entreprise si isCompany = true
    //   populateContact(val)
    // })

    watch(autocompleteContacts, val => {
      if (val.length) {
        if ('companyEntity' in currentContact.value) {
          if (
              ('id' in currentContact.value.companyEntity) ||
              ('id' in currentContact.value.userEntity)
          ) {
            if (currentContact.value._isCompany) {
              populateContact({
                id: currentContact.value.companyEntity.id,
                _display: currentContact.value.companyEntity._display,
                _type: 'company'
              }, true)
            } else {
              populateContact({
                id: currentContact.value.userEntity.id,
                _type: 'user'
              }, true)
            }
          }
        } else {
          populateContact(props.contact, true)
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedContact } = useContacts()

    const populateContact = (contact, initialPopulation = false) => {
      // console.log('populateContact', contact)
      if (contact != null) {
        localModel.value = autocompleteContacts.value.find(c => {
          if (initialPopulation) {
            return c.id == (isObject(contact) ? contact.id : contact) &&
                c._type == (props.isInitialContactCompany ? 'company' : 'user')
          } else {
            return c.id == contact.id &&
                c._type == contact._type
          }
        })
      }
    }

    const newSelectedContact = (selectedContact) => {
      console.log(selectedContact)
      emit('update:contact', selectedContact)
      emit('change')

      if (selectedContact != null && '_type' in selectedContact) {
        emit('updateCompany', selectedContact._type == 'company' ? selectedContact : null)
        emit('updateIndividual', selectedContact._type == 'user' ? selectedContact : null)
      } else {
        emit('updateCompany', null)
        emit('updateIndividual', null)
      }

    }

    const removeMe = (listContacts) => {
      return listContacts.filter(c => {
        if (c.id == getUserData().id && c._type == 'user') {
        } else {
          return true
        }
      })
    }

    const selectContact = (isNew = false) => {
      if (isNew) {
        resetContact()
        contactModalShow.value = true

      } else {
        if (localModel.value != null) {
          isContactEdit.value = true

          if (localModel.value._type == 'company') {
            store.dispatch('company/getCompany', localModel.value.id)
                .then(result => {
                  console.log(result)
                  currentContact.value = {
                    _isCompany: true,
                    companyEntity: result,
                    userEntity: store.getters['company/getEmptyCompany']
                  }
                  contactModalShow.value = true
                })
          } else {
            store.dispatch('user/getUser', localModel.value.id)
                .then(result => {
                  currentContact.value = {
                    _isCompany: true,
                    companyEntity: store.getters['user/getEmptyUser'],
                    userEntity: result.data
                  }
                  contactModalShow.value = true
                })
          }
        } else {
          resetContact()
        }
      }

    }

    const resetContact = () => {
      currentContact.value = JSON.parse(JSON.stringify({
        _isCompany: true,
        companyEntity: store.getters['company/getEmptyCompany'],
        userEntity: store.getters['user/getEmptyUser']
      }))
      isContactEdit.value = false
    }

    const submitValidatedContactLocal = () => {
      // console.log(currentContact.value)
      submitValidatedContact(currentContact.value)
          .then(response => {
            console.log(response.data)
            if (isContactEdit.value) {
              store.commit('contact/UPDATE_AUTOCOMPLETECONTACT', {
                contact: response.data,
                type: currentContact.value._isCompany ? 'company' : 'user'
              })
            } else {
              if (currentContact.value._isCompany) {
                currentContact.value.companyEntity = response.data
                store.commit('contact/ADD_AUTOCOMPLETECONTACT', {
                  contact: response.data,
                  type: 'company'
                })
              } else {
                currentContact.value.userEntity = response.data
                store.commit('contact/ADD_AUTOCOMPLETECONTACT', {
                  contact: response.data,
                  type: 'company'
                })
              }
            }

            contactModalShow.value = false
            emit('contactUpdated')
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateContact(props.contact, true)

    return {
      // Components

      // Data
      localModel,
      currentContact,
      isContactEdit,
      contactModalShow,

      // Computed
      listContacts,

      // Methods
      newSelectedContact,
      selectContact,
      submitValidatedContactLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>