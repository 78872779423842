<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteAddress')"
  >

    <template #iconContent>
      <b-avatar
          size="lg"
          variant="light-primary"
          :text="address.city"
          class="my-auto"
      />
    </template>

    <template #bodyContent>
      <div class="text-truncate">{{ address.street }}</div>
      <div class="text-truncate">
        <span v-if="address.zipCode">{{address.zipCode}}, </span>
        <span>{{address.city}}</span>
      </div>
      <div class="text-truncate">{{ getCountry }}</div>
    </template>
  </generic-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    address: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const getCountry = computed( () => {
      return store.getters['address/getCountry'](props.address.country)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      getCountry,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>