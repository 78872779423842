<template>
  <div>
    <b-row>
<!--      <b-col cols="12"-->
<!--             class="d-md-none">-->
<!--        test xs A-->
<!--      </b-col>-->
<!--      <b-col cols="12"-->
<!--             class="d-md-none">-->
<!--        test xs B-->
<!--      </b-col>-->

      <b-col cols="0"
             md="12"
             class="d-none d-md-block">
        <div class="d-flex justify-content-between">
          <div class="w-100 text-truncate">
            <div class="h1 text-primary mb-0">{{ ticket.title }}</div>
            <small><i>{{ticket.logs[0].by._display}}</i></small>

          </div>
          <div>
            <div class="h2 my-auto">
              {{ ticket.number }}
            </div>
            <div class="w-100 text-center">
              <b-badge
                  pill
                  :variant="'light-'+getTicketColorPriority(ticket)"
              >
                <small class="mx-50">{{ $t(ticket.priority) }}</small>
              </b-badge>
            </div>

          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12"
             md="6">

        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>{{ capitalize($t('type')) }}</span>
            <b-badge
                variant="primary"
                pill
                class="badge-round"
            >
              {{ $t(ticket.type) }}
            </b-badge>
          </b-list-group-item>

          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>{{ capitalize($t('module')) }}</span>
            <b>{{ $t(ticket.module) }}</b>
          </b-list-group-item>

          <b-list-group-item
              @click="'window.location.href='+ticket.page"
              class="d-flex justify-content-between align-items-center"
          >
            <span>{{ capitalize($t('page')) }}</span>
            <span v-if="ticket.page != null">{{ ticket.page }}</span>
            <span v-else>-</span>
          </b-list-group-item>
        </b-list-group>

      </b-col>
      <b-col cols="12"
             md="6">
        <content-document
            v-for="(file, index) in ticket.files"
            :document="file"
            :isParentMethodPriority="true"
            :is-deletable="false"
            @click="download(file)"
            @download="download(file)"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <span v-html="ticket.content"/>
      </b-col>
    </b-row>

    <b-row
        v-if="$can('manage', 'all')"
        class="mt-2"
    >
      <b-col cols="12">
        <div class="d-flex float-right">
          <!--          Next step-->
          <button-next
              v-if="getUserData().id == 1 && ticket.status != 'Done'"
              @click.native="nextStep(ticket)"
              v-b-tooltip.hover.left="capitalize($t('NextStep'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              class="mr-1"
          />

          <!--          Cancel-->
          <button-delete
              v-if="getUserData().id == 1 && ticket.status != 'Done' && ticket.status != 'Cancelled'"
              @click.native.stop="cancelTicket(ticket)"
              v-b-tooltip.hover.left="capitalize($t('cancel'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              class="mr-1"
          />

          <!--          Delete-->
          <button-remove
              v-if="ticket.status == 'New'"
              @click.native.stop="deleteTicketAlert(ticket)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
        </div>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useTickets } from '../../views/support/tickets/useTickets'
import { capitalize } from '../../utils/filter'
import { getUserData } from '../../utils/utils'

import ContentDocument from '../card/components/DocumentContent'
import ButtonNext from '../button/Next'
import ButtonDelete from '../button/Delete'
import ButtonRemove from '../button/Remove'

export default {
  components: {
    ContentDocument,
    ButtonNext,
    ButtonDelete,
    ButtonRemove
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      nextStep,
      cancelTicket,
      removeTicket,
    } = useTickets()

    const download = (file) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = file.url
      link.click()
    }

    const getTicketColorPriority = (ticket) => {
      if (ticket.priority == 'Normal') {
        return 'primary'
      } else if (ticket.priority == 'High') {
        return 'warning'
      } else if (ticket.priority == 'Urgent') {
        return 'danger'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      getUserData,

      // Data

      // Computed

      // Methods
      nextStep,
      cancelTicket,
      removeTicket,
      getTicketColorPriority,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteTicketAlert (ticket) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theTicket') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeTicket(ticket)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>