<template>
    <b-row>
        <b-col cols="12">

            <b-card no-body>
                <b-table
                        responsive
                        :items="items"
                        :fields="columnsLocal"
                        :tbody-tr-class="rowClass"
                        :no-local-sorting="true"
                        :selectable="['single', 'multi'].includes(selectable)"
                        :select-mode="selectable"
                        striped
                        hover
                        @sort-changed="tableSort"
                        @row-clicked="$emit('selectItem', $event)"
                        @row-selected="selected = $event"
                >
                    <!--              Column head-->
                    <template
                            v-for="(tableColumn, index) in columns"
                            v-slot:[`head(${tableColumn.key})`]="data">

                        <slot
                                :name="'listView_head_'+tableColumn.key"
                                :item="data.field.display"
                        >
                            <span v-if="'cell' in data.field && data.field.cell.isSelect && selectable == 'multi'">
                                <!--                <b-form-checkbox v-model="selectAll"/>-->
                            </span>
                            <span v-else>{{ data.field.display }}</span>

                        </slot>

                    </template>

                    <!--              Cell-->
                    <template
                            v-for="(tableColumn, index) in columns"
                            v-slot:[`cell(${tableColumn.key})`]="data">

                        <div
                                :class="{'text-truncate': tableColumn.maxWidth?true:false}"
                                :style="tableColumn.maxWidth?{maxWidth: tableColumn.maxWidth}:{}"
                        >

                            <slot
                                    :name="'listView_cell_'+tableColumn.key"
                                    :item="data.item"

                            >
                                <!--                Custom cell-->
                                <div
                                        v-if="'cell' in tableColumn"
                                        class="text-nowrap"
                                        :class="{'text-center': tableColumn.cell.center}"
                                >
                                    <!--                  Select-->
                                    <span v-if="tableColumn.cell.isSelect && selectable == 'multi'">
                                        <b-form-checkbox v-model="selected"
                                                         :value="data.item"/>
                                    </span>

                                    <!--                  Currency-->
                                    <span v-if="tableColumn.cell.isCurrency">
                                        <span v-if="!isNaN(parseFloat(data.item[tableColumn.key]))">
                                            {{ currency(parseFloat(data.item[tableColumn.key])) }}
                                        </span>

                                    </span>

                                    <!--                  Contact-->
                                    <span v-else-if="tableColumn.cell.isContact">
                                        <span v-if="tableColumn.key in  data.item">
                                            {{ data.item[tableColumn.key]._display }}
                                        </span>
                                        <span v-else-if="'customerCompany' in data.item && data.item.customerCompany != null && ('_display' in data.item.customerCompany)">
                                            {{ data.item.customerCompany._display }}
                                        </span>
                                        <span v-else-if="'customerIndividual' in data.item && data.item.customerIndividual != null && ('_display' in data.item.customerIndividual)">
                                            {{ data.item.customerIndividual._display }}
                                        </span>
                                    </span>

                                    <!--                  Use _display-->
                                    <span v-else-if="tableColumn.cell.useFirstLogBy">
                                        {{
                                        data.item[tableColumn.key] ? data.item[tableColumn.key][0].by._display : '-'
                                        }}
                                    </span>

                                    <!--                  Use _display-->
                                    <span v-else-if="tableColumn.cell.useDisplay">
                                        {{ data.item[tableColumn.key] ? data.item[tableColumn.key]._display : '-' }}
                                    </span>

                                    <!--                  Use name-->
                                    <span v-else-if="tableColumn.cell.useName">
                                        <span v-if="tableColumn.cell.translate">
                                            {{ data.item[tableColumn.key] ? $t(data.item[tableColumn.key].name) : '-' }}
                                        </span>
                                        <span v-else>
                                            {{ data.item[tableColumn.key] ? data.item[tableColumn.key].name : '-' }}
                                        </span>

                                    </span>

                                    <!--                  Display month-->
                                    <span v-else-if="tableColumn.cell.displayMonth">
                                        {{ data.item[tableColumn.key]|moment('MMMM YYYY')|capitalize }}
                                    </span>

                                    <!--                  Is date time-->
                                    <span v-else-if="tableColumn.cell.isDateTime">
                                        {{ data.item[tableColumn.key]|moment('LLL') }}
                                    </span>

                                    <!--                  Is date-->
                                    <span v-else-if="tableColumn.cell.isDate">
                                        {{ data.item[tableColumn.key]|moment('L') }}
                                    </span>

                                    <!-- Date start half day-->
                                    <div
                                            v-else-if="tableColumn.cell.isDateStartHalfDay"
                                            class="text-nowrap"
                                            :class="{'text-center': tableColumn.cell.center}"
                                    >
                                        {{ data.item[tableColumn.key]|moment('LL') }}
                                        <span v-if="isMorning(data.item[tableColumn.key])">{{
                                            $t('startMorning')
                                            }}
                                        </span>
                                        <span v-else>{{ $t('startMorning') }}</span>
                                    </div>

                                    <!-- Date end half day-->
                                    <div
                                            v-else-if="tableColumn.cell.isDateEndHalfDay"
                                            class="text-nowrap"
                                            :class="{'text-center': tableColumn.cell.center}"
                                    >
                                        {{ data.item[tableColumn.key]|moment('LL') }}
                                        <span v-if="isMorning(data.item[tableColumn.key])">{{ $t('endMorning') }}</span>
                                        <span v-else>{{ $t('endAfternoon') }}</span>
                                    </div>

                                    <!--                  Use badge-->
                                    <span v-else-if="tableColumn.cell.useBadge">
                                        <!--                    Badge priority-->
                                        <b-badge
                                                v-if="tableColumn.cell.type =='priority'"
                                                pill
                                                :variant="getBadgeColorPriority(data.item[tableColumn.key])"
                                        >
                                            <small v-if="tableColumn.cell.translate">{{
                                                $t(data.item[tableColumn.key])
                                                }}
                                            </small>
                                            <small v-else>{{ data.item[tableColumn.key] }}</small>
                                        </b-badge>

                                        <!--                    Badge Payment status-->
                                        <b-badge
                                                v-if="tableColumn.cell.type =='paymentStatus' && data.item._paymentStatus != null"
                                                pill
                                                :variant="getBadgeColorPaymentStatus(data.item)"
                                        >
                                            <small v-if="tableColumn.cell.translate">
                                                <span v-if="data.item._paymentStatus == 'Unlettered'">
                                                    {{
                                                    $tc('Unlettered:',
                                                        getDaysRemaining(data.item._dueDate ? data.item._dueDate : (data.item.dueDate ? data.item.dueDate : (data.item.month ? data.item.month : data.item.paymentDate))),
                                                        {
                                                          days: Math.abs(getDaysRemaining(data.item._dueDate ? data.item._dueDate : (data.item.dueDate ? data.item.dueDate : (data.item.month ? data.item.month : data.item.paymentDate)))),
                                                          suffix: getDaysRemaining(data.item._dueDate ? data.item._dueDate : (data.item.dueDate ? data.item.dueDate : (data.item.month ? data.item.month : data.item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')
                                                        }
                                                    )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{ $t(data.item._paymentStatus) }}
                                                </span>

                                                <!--                        <span v-if="data.item._balance ==0">-->
                                                <!--                          {{ $t('Paid') }}-->
                                                <!--                        </span>-->
                                                <!--                        <span v-else>-->
                                                <!--                          {{-->
                                                <!--                            $tc(-->
                                                <!--                                data.item._paymentStatus + ':',-->
                                                <!--                                getDaysRemaining(data.item._dueDate ? data.item._dueDate : data.item.paymentDate),-->
                                                <!--                                {-->
                                                <!--                                  days: Math.abs(getDaysRemaining(data.item._dueDate ? data.item._dueDate : data.item.paymentDate)),-->
                                                <!--                                  suffix: getDaysRemaining(data.item._dueDate ? data.item._dueDate : data.item.paymentDate) >= 0 ? $t('remaining') : $t('ofLate')-->
                                                <!--                                }-->
                                                <!--                            )-->
                                                <!--                          }}-->
                                                <!--                        </span>-->
                                            </small>
                                            <small v-else>{{ data.item._paymentStatus }}</small>
                                        </b-badge>

                                        <!--                    Badge bank transaction payment relation status-->
                                        <b-badge
                                                v-if="tableColumn.cell.type =='bankTransactionPaymentRelationStatus'"
                                                pill
                                                :variant="getBankTransactionPaymentRelationStatus(data.item)=='partialReconciliation'?'danger':getBadgeColorBankTransactionPaymentRelationStatus(getBankTransactionPaymentRelationStatus(data.item)=='reconciliated')"
                                        >
                                            <small>{{ $t(getBankTransactionPaymentRelationStatus(data.item)) }}</small>
                                            <!--                      <small v-if="getBankTransactionPaymentRelationStatus(data.item) == 'reconciliated'">-->
                                            <!--                        {{ $t('reconciliated') }}-->
                                            <!--                      </small>-->
                                            <!--                      <small v-else>{{ $t('unreconciliated') }}</small>-->
                                        </b-badge>

                                        <!--                    Badge payment bank transaction  relation status-->
                                        <b-badge
                                                v-if="tableColumn.cell.type =='paymentBankTransactionRelationStatus'"
                                                pill
                                                :variant="getBadgeColorBankTransactionPaymentRelationStatus(data.item.bankTransaction)"
                                        >
                                            <small v-if="data.item.bankTransaction!= null">{{
                                                $t('reconciliated')
                                                }}
                                            </small>
                                            <small v-else>{{ $t('unreconciliated') }}</small>
                                        </b-badge>

                                        <!--                      Event status-->
                                        <b-badge
                                                v-if="tableColumn.cell.type =='eventStatus'"
                                                pill
                                                :variant="'light-'+(data.item.isValidated?'success':(data.item.isValidated==false?'danger':'secondary'))"
                                                class="text-capitalize"
                                        >
                                            <icon :icon="getIcon(data.item)"/>
                                        </b-badge>

                                    </span>

                                    <!--                  Use icon-->
                                    <span v-else-if="tableColumn.cell.useIcon">
                                        <!--                    Customer invoice type-->
                                        <icon
                                                v-if="tableColumn.cell.type =='customerStatus'"
                                                :class="'text-'+(data.item.billNumber==null?'secondary':'primary')"
                                                v-b-tooltip.hover.left="capitalize(data.item.billNumber==null?$t('draft'):$t('sent'))"
                                                :icon="data.item.billNumber==null?'pencil-ruler':'paper-plane'"
                                        />
                                        <!--                    Icon status-->
                                        <icon
                                                v-if="tableColumn.cell.type =='status'"
                                                :class="'text-'+getStatusColor(data.item.status)"
                                                v-b-tooltip.hover.left="$t(data.item.status)"
                                                :icon="getStatusIcon(data.item.status)"
                                        />
                                        <!--                    Icon supplier invoice type-->
                                        <icon
                                                v-if="tableColumn.cell.type =='supplierInvoiceType'"
                                                v-b-tooltip.hover.left="capitalize($t(data.item.type))"
                                                :icon="getSupplierInvoiceTypeIcon(data.item.type).icon"
                                        />
                                        <!--                    Icon offer-->
                                        <div v-if="tableColumn.cell.type =='offerStatus'">
                                            <b-avatar
                                                    :id="`offer-row-${data.item.id}`"
                                                    size="32"
                                                    :variant="`light-${getOfferIcon(data.item._status).variant}`"
                                            >
                                                <icon
                                                        :icon="getOfferIcon(data.item._status).icon"
                                                />
                                            </b-avatar>
                                            <b-tooltip
                                                    :target="`offer-row-${data.item.id}`"
                                                    placement="left"
                                            >
                                                <p class="mb-0">
                                                    {{ $t(data.item._status) }}
                                                </p>
                                            </b-tooltip>
                                        </div>
                                        <!--                    <icon-->
                                        <!--                        v-if="tableColumn.cell.type =='offerStatus'"-->
                                        <!--                        v-b-tooltip.hover.left="capitalize($t(data.item._status))"-->
                                        <!--                        :icon="getOfferIcon(data.item._status).icon"-->
                                        <!--                    />-->
                                    </span>

                                    <!--                  Translate-->
                                    <span v-else-if="tableColumn.cell.translate">
                                        {{ $t(data.item[tableColumn.key]) }}
                                    </span>

                                    <!--                  Array-->
                                    <span v-else-if="Array.isArray(data.item[tableColumn.key])">
                                        {{ displayArray(data.item[tableColumn.key]) }}
                                    </span>

                                    <!--                  Else-->
                                    <span v-else>
                                        {{ data.item[tableColumn.key] }}
                                    </span>

                                </div>
                                <div v-else>
                                    <span>{{ data.item[tableColumn.key] }}</span>
                                </div>
                            </slot>
                        </div>
                    </template>


                    <!--              Actions-->
                    <template #cell(actions)="data">
                        <slot
                                name="listView_cell_actions"
                                :item="data.item"
                        >
                        </slot>
                    </template>

                </b-table>
            </b-card>

        </b-col>
    </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../utils/utils'
import { currency, capitalize } from '../../../utils/filter'

import moment from 'moment'
import i18n from '../../../libs/i18n'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    sortColumn: {
      type: String,
      default: 'createdAt'
    },
    sortOrder: {
      type: String,
      default: 'desc'
    },
    isActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columnsLocal = ref(props.columns)
    const selected = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const selectAll = computed({
      get () {
        return props.items.length ? selected.value.length == props.items.length : false
      },
      set (val) {
        if (val) {
          selected.value = JSON.parse(JSON.stringify(props.items))
        } else if (!val && selected.value.length == props.items.length) {
          selected.value = []
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selected, val => {
      console.log(val)
      emit('rowSelected', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const rowClass = (item) => {
      if (item._isDisabled == true) return 'table-secondary'
    }

    const tableSort = (ctx) => {
      emit('update:sortColumn', ctx.sortBy)
      emit('update:sortOrder', ctx.sortDesc ? 'desc' : 'asc')
    }

    const getBadgeColorPriority = (priority) => {
      if (priority == 'Normal') {
        return 'primary'
      } else if (priority == 'High') {
        return 'warning'
      } else if (priority == 'Urgent') {
        return 'danger'
      }
    }

    const getBadgeColorPaymentStatus = (item) => {
      if (item._paymentStatus == 'Lettered') {
        return 'success'
      } else if (item._paymentStatus == 'Partially lettered') {
        return 'warning'
      } else if (item._paymentStatus == 'Unlettered') {
        return 'danger'
      }

      // if (item._balance == 0) {
      //   return 'success'
      // }
      //
      // if (
      //     item._paymentStatus == 'Waiting for payment' ||
      //     item._paymentStatus == 'Open'
      // ) {
      //   return 'secondary'
      // } else if (item._paymentStatus == 'Partial payment') {
      //   return 'warning'
      // } else if (item._paymentStatus == 'Unpaid') {
      //   return 'danger'
      // }
    }

    const getBadgeColorBankTransactionPaymentRelationStatus = (relation) => {
      if (relation) {
        return 'success'
      } else {
        return 'warning'
      }
    }

    // const getBadgeColorPaymentBankTransactionRelationStatus = (relation) => {
    //   if (relation != null) {
    //     return 'success'
    //   } else {
    //     return 'warning'
    //   }
    // }

    const getStatusColor = (itemStatus) => {
      if (itemStatus == 'New') {
        return 'secondary'
      } else if (itemStatus == 'In progress') {
        return 'primary'
      } else if (itemStatus == 'Done') {
        return 'success'
      } else if (itemStatus == 'Cancelled') {
        return 'danger'
      }
    }

    const getStatusIcon = (itemStatus) => {
      if (itemStatus == 'New') {
        return 'hourglass-start'
      } else if (itemStatus == 'In progress') {
        return 'stopwatch'
      } else if (itemStatus == 'Done') {
        // if (ticket.isCorrectionValidated) {
        //   return 'check-double'
        // } else if (ticket.isCorrectionValidated == null) {
        return 'check'
        // } else {
        //   return 'exclamation-triangle'
        // }
      } else if (itemStatus == 'Cancelled') {
        return 'trash-alt'
      }
    }

    const getDaysRemaining = (dueDate) => {
      return parseInt(moment.duration(moment(dueDate).diff(moment().startOf('day'))).asDays())
    }

    const getBankTransactionPaymentRelationStatus = (bankTransaction) => {
      if (
        (
          bankTransaction.payments != null &&
          bankTransaction.payments.length != 0
        ) ||
        bankTransaction.linkedTransaction != null ||
        bankTransaction.linkedBankTransaction != null ||
        (
          bankTransaction.attachment != null &&
          bankTransaction.attachmentCategory != null
        )
      ) {
        // console.log(bankTransaction.id)
        // console.log(parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)))
        // console.log(parseFloat(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit))

        if (
          parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)) !=
          parseFloat(bankTransaction.debit ? bankTransaction.debit : bankTransaction.credit)) {
          // console.log('55555555')
          return 'partialReconciliation'
        } else {
          return 'reconciliated'
        }
      } else {
        return 'unreconciliated'
      }

      // if (
      //     (
      //         bankTransaction.payments != null &&
      //         bankTransaction.payments.length != 0 &&
      //         parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)) == parseFloat(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit)
      //     ) ||
      //     bankTransaction.linkedTransaction != null ||
      //     bankTransaction.linkedBankTransaction != null ||
      //     (
      //         bankTransaction.attachment != null &&
      //         bankTransaction.attachmentCategory != null
      //     )
      // ) {
      //   console.log(bankTransaction.id)
      //   console.log(parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)))
      //   console.log(parseFloat(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit))
      //
      //   if (
      //       parseFloat(bankTransaction.payments.reduce((x, item) => x + parseFloat(item.amount), 0)) !=
      //       parseFloat(bankTransaction.debit ? bankTransaction.debit : -bankTransaction.credit)) {
      //     console.log('55555555')
      //     return 'partialReconciliation'
      //   } else {
      //     return 'reconciliated'
      //   }
      //
      // } else {
      //   return 'unreconciliated'
      // }
    }

    const displayArray = array => {
      let output = []
      array.forEach(item => {
        if (item) {
          if (item.startsWith('_i18n_')) {
            output.push(i18n.t(item))
          } else {
            output.push(item)
          }
        }

      })

      return output.join(', ')
    }

    const getSupplierInvoiceTypeIcon = (type) => {
      if (type == 'toll') return { variant: 'secondary', icon: 'car-side' }
      else if (type == 'catering') return { variant: 'secondary', icon: 'utensils' }
      else if (type == 'accommodation') return { variant: 'secondary', icon: 'suitcase-rolling' }
      else if (type == 'production') return { variant: 'secondary', icon: 'tools' }
      else return { variant: 'secondary', icon: 'wallet' }
    }

    const getOfferIcon = (status) => {
      if (status == 'Canceled') return { variant: 'danger', icon: 'times' }
      else if (status == 'Fully invoiced') return { variant: 'success', icon: 'check' }
      else if (status == 'Partially invoiced') return { variant: 'warning', icon: 'spinner' }
      else if (status == 'Declined') return { variant: 'danger', icon: 'times' }
      else if (status == 'Waiting for invoicing') return { variant: 'primary', icon: 'signature' }
      else if (status == 'Waiting for signature') return { variant: 'secondary', icon: 'hourglass' }

    }

    const isMorning = (date) => {
      if (moment(date).format('HH') <= 12) {
        return true
      } else {
        return false
      }
    }

    const getIcon = (event) => {
      if (event.isValidated) {
        return 'check'
      } else if (event.isValidated == false) {
        return 'times'
      } else {
        return 'stopwatch'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.isActionColumnEnabled) {
      columnsLocal.value.push({ key: 'actions', display: i18n.tc('action', 2) })
    }

    if (props.selectable == 'multi') {
      columnsLocal.value.unshift({
        display: '',
        key: 'select',
        sortable: false,
        cell: {
          isSelect: true
        }
      })
    }

    return {
      // Components
      isObject,
      currency,
      capitalize,

      // Data
      columnsLocal,
      selected,

      // Computed
      selectAll,

      // Methods
      rowClass,
      tableSort,
      getBadgeColorPriority,
      getBadgeColorPaymentStatus,
      getBadgeColorBankTransactionPaymentRelationStatus,
      getStatusColor,
      getStatusIcon,
      getDaysRemaining,
      getBankTransactionPaymentRelationStatus,
      displayArray,
      getSupplierInvoiceTypeIcon,
      getOfferIcon,
      isMorning,
      getIcon,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>