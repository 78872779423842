import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { getUserData } from '../../../utils/utils'
import { useTickets } from './useTickets'

import i18n from '../../../libs/i18n'
import store from '../../../store'
import BaseTemplate from '../../../components/base/Base'
import ButtonNext from '../../../components/button/Next'
import ButtonValidate from '../../../components/button/Validate'
import ButtonExclamationTriangle from '../../../components/button/ExclamationTriangle'
import ButtonEdit from '../../../components/button/Edit'
import ButtonDelete from '../../../components/button/Delete'
import ButtonRemove from '../../../components/button/Remove'
import ButtonView from '../../../components/button/View'
import ModalTicket from '../../../components/prompt/Ticket'
import useAPI from '../../../utils/useAPI'

import Contact2 from '../../../components/input/Contact2'

export const mixinTickets = {
  components: {
    BaseTemplate,
    ButtonNext,
    ButtonValidate,
    ButtonExclamationTriangle,
    ButtonEdit,
    ButtonDelete,
    ButtonRemove,
    ButtonView,
    ModalTicket,
    Contact2
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('number')),
        key: 'number',
        sortable: true,
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: true
      },
      {
        display: capitalize(i18n.t('type')),
        key: 'type',
        sortable: true,
        cell: {
          translate: true,
          center: true
        }
      },
      {
        display: capitalize(i18n.t('priority')),
        key: 'priority',
        sortable: true,
        cell: {
          translate: true,
          useBadge: true,
          type: 'priority',
          center: true
        }
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: true,
        cell: {
          translate: true,
          useIcon: true,
          type: 'status',
          center: true
        }
      },
    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('isAwaitingAttention')),
        key: '_filter_isAwaitingAttention',
        searchCriteria: [{
          column_name: 'status',
          comparator: 'or',
          search_text_0: 'New',
          search_text_1: 'In progress',
        }],
        selected: true
      },
      {
        text: capitalize(i18n.tc('isAwaitingValidation')),
        key: '_filter_isAwitingValidation',
        searchCriteria: [{
          column_name: 'status',
          comparator: 'equal',
          search_text: 'Done',
        }, {
          column_name: 'isCorrectionValidated',
          comparator: 'null',
        }],
      },
      {
        text: capitalize(i18n.tc('isFullyCompleted')),
        key: '_filter_isFullyCompleted',
        searchCriteria: [{
          column_name: 'status',
          comparator: 'equal',
          search_text: 'Done',
        }, {
          column_name: 'isCorrectionValidated',
          comparator: 'true',
        }],
      },
      {
        text: capitalize(i18n.tc('isAwaitingCorrections')),
        key: '_filter_isAwaitingCorrections',
        searchCriteria: [{
          column_name: 'status',
          comparator: 'equal',
          search_text: 'Done',
        }, {
          column_name: 'isCorrectionValidated',
          comparator: 'false',
        }],
      },
      {
        text: capitalize(i18n.tc('myTickets')),
        key: '_filter_myTickets',
        searchCriteria: [{
          column_name: 'createdBy',
          comparator: 'me',
        }],
        selected: getUserData().id != 1
      },
    ])

    const ticketModalShow = ref(false)
    const ticketModalTitle = ref(i18n.t('NewTicket'))
    const currentTicket = ref({})
    const submitTicketFormRef = ref(null)
    const isViewModal = ref(false)

    // const contactTest = ref({id:492})
    // watch(contactTest, val => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { tickets, ticketsContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(ticketModalShow, val => {
      if (val == false) {
        resetTicket()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchTickets, fetchAutocompleteContacts } = useAPI()

    const {
      submitValidatedTicket,
      nextStep,
      validateCorrection,
      unValidateCorrection,
      cancelTicket,
      removeTicket
    } = useTickets()

    const resetTicket = () => {
      ticketModalTitle.value = i18n.t('NewTicket')
      currentTicket.value = JSON.parse(JSON.stringify(store.getters['ticket/getEmptyTicket']))
      isViewModal.value = false
    }

    const hasInitialFilter = () => {
      return filters.value.some(f => f.selected)
    }

    const selectTicket = (ticket) => {
      ticketModalTitle.value = i18n.t('EditTicket')
      currentTicket.value = JSON.parse(JSON.stringify(ticket))

      ticketModalShow.value = true
    }

    const viewTicket = (ticket) => {
      ticketModalTitle.value = i18n.t('DisplayTicket')
      currentTicket.value = JSON.parse(JSON.stringify(ticket))
      isViewModal.value = true

      ticketModalShow.value = true
    }

    const submitValidatedTicketLocal = () => {
      submitValidatedTicket(currentTicket.value)
        .then(response => {
          ticketModalShow.value = false
          isViewModal.value = false
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (!hasInitialFilter()) {
      fetchTickets()
    }
    resetTicket()
    // fetchAutocompleteContacts()

    return {
      // Components
      capitalize,
      getUserData,

      // Data
      columns,
      filters,
      ticketModalShow,
      ticketModalTitle,
      currentTicket,
      submitTicketFormRef,
      isViewModal,

      // contactTest,

      // Computed
      tickets,
      ticketsContext,

      // Methods
      nextStep,
      validateCorrection,
      unValidateCorrection,
      cancelTicket,
      removeTicket,
      fetchTickets,
      selectTicket,
      viewTicket,
      submitValidatedTicketLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteTicketAlert (ticket) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theTicket') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeTicket(ticket)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}