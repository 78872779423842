<template>
  <div
      v-b-hover="handleHover"
      class="d-flex align-content-center justify-content-between cursor-pointer p-50"
      :class="{'bg-primary bg-lighten-5': (isHovered||isSelected)}"
      @click="$emit('click')"
  >
    <b-media no-body
             class="w-100 overflow-hidden">
      <b-media-aside class="d-flex h-100">

        <slot name="iconContent"/>

      </b-media-aside>

      <b-media-body class="overflow-hidden pr-1">

        <slot name="bodyContent"/>

      </b-media-body>
    </b-media>
    <div
        v-if="isHovered"
        class="d-flex font-weight-bolder my-auto"
    >

      <button-download
          v-if="isDownloadable"
          v-b-hover="handleDownloadHover"
          @click.native.stop="$emit('download')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <button-view
          v-if="isViewable"
          v-b-hover="handleViewHover"
          @click.native.stop="$emit('view')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <button-edit
          v-if="isEditable"
          v-b-hover="handleEditHover"
          @click.native.stop="$emit('edit')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <button-external-link
          v-if="isLinkable"
          v-b-hover="handleToRouteHover"
          @click.native.stop="$emit('toRoute')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <button-payment
          v-if="isPayable"
          v-b-hover="handlePayableHover"
          @click.native.stop="$emit('pay')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <button-delete
          v-if="isDeletable"
          :disabled="!allowDeletable"
          v-b-hover="handleDeleteHover"
          @click.native.stop="$emit('delete')"
          :withIcon="true"
          :withText="false"
          :withBorder="false"
          size="sm"
      />

      <!--      <icon-->
      <!--          v-if="isDownloadable"-->
      <!--          v-b-hover="handleDownloadHover"-->
      <!--          @click.native.stop="$emit('download')"-->
      <!--          icon="download"-->
      <!--          :class="{'text-primary': isDownloadIconHovered}"-->
      <!--          class="mx-25 px-50"/>-->
      <!--      <icon-->
      <!--          v-if="isLinkable"-->
      <!--          v-b-hover="handleToRouteHover"-->
      <!--          @click.native.stop="$emit('toRoute')"-->
      <!--          icon="external-link-alt"-->
      <!--          :class="{'text-primary': isToRouteIconHovered}"-->
      <!--          class="mx-25 px-50"/>-->
      <!--      <icon-->
      <!--          v-if="isDeletable"-->
      <!--          :disabled="!allowDeletable"-->
      <!--          v-b-hover="handleDeleteHover"-->
      <!--          @click.native.stop="$emit('delete')"-->
      <!--          icon="times"-->
      <!--          :class="{'text-danger': isDeleteIconHovered}"-->
      <!--          class="mx-25 px-50"-->
      <!--      />-->
    </div>
  </div>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import ButtonDownload from '@/components/button/Download'
import ButtonView from '../../button/View'
import ButtonEdit from '@/components/button/Edit'
import ButtonExternalLink from '../../button/ExternalLink'
import ButtonPayment from '@/components/button/Payment'
import ButtonDelete from '@/components/button/Delete'

export default {
  components: {
    ButtonDownload,
    ButtonView,
    ButtonEdit,
    ButtonExternalLink,
    ButtonPayment,
    ButtonDelete
  },
  props: {
    isDownloadable: {
      type: Boolean,
      default: true
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    isLinkable: {
      type: Boolean,
      default: false
    },
    isPayable: {
      type: Boolean,
      default: false
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    allowDeletable: {
      type: Boolean,
      default: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)
    const isDownloadIconHovered = ref(false)
    const isViewIconHovered = ref(false)
    const isEditIconHovered = ref(false)
    const isToRouteIconHovered = ref(false)
    const isPayableIconHovered = ref(false)
    const isDeleteIconHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = (hovered) => {
      isHovered.value = hovered
    }
    const handleDownloadHover = (hovered) => {
      isDownloadIconHovered.value = hovered
    }
    const handleViewHover = (hovered) => {
      isViewIconHovered.value = hovered
    }
    const handleEditHover = (hovered) => {
      isEditIconHovered.value = hovered
    }
    const handleToRouteHover = (hovered) => {
      isToRouteIconHovered.value = hovered
    }
    const handlePayableHover = (hovered) => {
      isPayableIconHovered.value = hovered
    }
    const handleDeleteHover = (hovered) => {
      isDeleteIconHovered.value = hovered
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,
      isDownloadIconHovered,
      isViewIconHovered,
      isEditIconHovered,
      isToRouteIconHovered,
      isPayableIconHovered,
      isDeleteIconHovered,

      // Computed

      // Methods
      handleHover,
      handleDownloadHover,
      handleViewHover,
      handleEditHover,
      handleToRouteHover,
      handlePayableHover,
      handleDeleteHover,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>