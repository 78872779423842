import store from '@/store'

export const useTickets = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const submitValidatedTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      if ('id' in ticket) {
        updateTicket(ticket)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewTicket(ticket)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/addTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/updateTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const nextStep = (ticket) => {
    return new Promise((resolve, reject) => {
      if (ticket.status == 'New' || ticket.status == 'Cancelled') {
        ticket.status = 'In progress'
        submitValidatedTicket(ticket).then(response => {
          resolve(response)
        })
      } else if (ticket.status == 'In progress') {
        ticket.status = 'Done'
        submitValidatedTicket(ticket).then(response => {
          resolve(response)
        })
      } else {
        reject()
      }
    })

  }

  const validateCorrection = (ticket) => {
    return new Promise((resolve, reject) => {
      if (ticket.isCorrectionValidated == false) {
        ticket.isCorrectionValidated = null
      } else {
        ticket.isCorrectionValidated = true
      }
      store.dispatch('ticket/updateTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const unValidateCorrection = (ticket) => {
    return new Promise((resolve, reject) => {
      ticket.isCorrectionValidated = false
      store.dispatch('ticket/updateTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const cancelTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      ticket.status = 'Cancelled'
      submitValidatedTicket(ticket).then(response => {
        resolve(response)
      })
    })

  }

  const removeTicket = (ticket) => {
    return new Promise((resolve, reject) => {
      store.dispatch('ticket/removeTicket', ticket)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    submitValidatedTicket,
    nextStep,
    validateCorrection,
    unValidateCorrection,
    cancelTicket,
    removeTicket
  }
}