<template>
    <div class="row">
        <div class="col-md-3 px-0">
            <div v-if="!isViewWindowsReady" class="text-center">
                <b-spinner variant="primary" label="Text Centered" />
            </div>

            <div v-else class="position-relative w-100 h-100">
                <div class="position-absolute overflow-auto w-100 h-100 pr-1">
                    <div
                            v-for="(item, index) in items"
                            :key="index"
                    >
                        <slot name="leftViewWindow"
                              :item="item"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9 pr-0">
            <div v-if="!isViewWindowsReady" class="text-center">
                <b-spinner variant="primary" label="Text Centered" />
            </div>

            <b-card v-else style="min-height: 700px">
                <div class="d-flex justify-content-between mb-1">
                    <h4>
                        <slot name="title"/>
                    </h4>

                    <div>

                        <slot name="buttons"/>

                        <!--          Close-->
                        <button-close
                                @click.native.stop="openViewWindowLocal=false"
                                v-b-tooltip.hover.left="$t('Close')"
                                :withIcon="true"
                                :withText="false"
                                :withBorder="false"
                                size="sm"
                        />
                    </div>
                </div>


                <slot name="windowView"/>
            </b-card>

        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import ButtonClose from '../../button/Close.vue'
import ButtonEdit from '../../button/Edit.vue'
import ButtonDownload from '../../button/Download.vue'
import ButtonDuplicate from '../../button/Duplicate.vue'

export default {
  components: { ButtonDuplicate, ButtonDownload, ButtonEdit, ButtonClose },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    openViewWindow: {
      type: Boolean
    },
    isViewWindowsReady: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const openViewWindowLocal = computed({
      get () {
        return props.openViewWindow
      },
      set (val) {
        emit('update:openViewWindow', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      openViewWindowLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>