import { ref, computed, watch } from '@vue/composition-api'
import moment from 'moment'
import Buttons from './header/Buttons'
import SearchFilter from './header/SearchFilter'
import GridView from './views/Grid'
import ListView from './views/List'
import ViewWindow from './views/ViewWindow'
import Pagination from './footer/Pagination'

export default {
  components: {
    Buttons,
    SearchFilter,
    GridView,
    ListView,
    Pagination,
    ViewWindow,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    itemView: {
      type: String,
      default: 'grid'
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    allowAddPayment: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    context: {
      type: Object,
      default: () => {}
    },
    isActionColumnEnabled: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: String,
      default: null
    },
    openViewWindow: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    isButtonAddPaymentDisabled: {
      type: Boolean,
      default: true
    },
    textButtonAdd: {
      type: String,
      default: ''
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const selectedView = ref('grid')
    const currentPage = ref(1)
    const perPage = ref(25)
    const sortColumn = ref(null)
    const sortOrder = ref(null)
    const searchCriteria = ref([])
    const query = ref('')
    const selectedFilters = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const criteriaQuery = computed(() => {
      if (query.value == '') {
        return {
          'column_name': '_search',
          'comparator': 'contains',
          'search_text': ''
        }
      } else {
        return {
          'column_name': '_search',
          'comparator': 'contains',
          'search_text': query.value
        }
      }
    })

    const criteriaFilters = computed(() => {
      let criteriaFilters = []

      selectedFilters.value.forEach(filter => {
        // console.log(filter)
        filter.searchCriteria.forEach(sc => {
          criteriaFilters.push(sc)
        })
      })

      return criteriaFilters
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(currentPage, val => {
      emit('fetch', {
        page: val,
        perPage: perPage.value,
        sortColumn: sortColumn.value,
        sortOrder: sortOrder.value,
        searchCriteria: searchCriteria.value
      })
    })
    watch(perPage, val => {
      currentPage.value = 1
      emit('fetch', {
        page: 1,
        perPage: val,
        sortColumn: sortColumn.value,
        sortOrder: sortOrder.value,
        searchCriteria: searchCriteria.value
      })
    })
    watch(sortColumn, val => {
      currentPage.value = 1
      emit('fetch', {
        page: 1,
        perPage: perPage.value,
        sortColumn: val,
        sortOrder: sortOrder.value,
        searchCriteria: searchCriteria.value
      })
    })
    watch(sortOrder, val => {
      currentPage.value = 1
      emit('fetch', {
        page: 1,
        perPage: perPage.value,
        sortColumn: sortColumn.value,
        sortOrder: val,
        searchCriteria: searchCriteria.value
      })
    })
    watch(criteriaQuery, val => {
      // console.log(searchCriteria.value)
      let searchColumnIndex = searchCriteria.value.findIndex(c => c.column_name == '_search')

      // console.log(searchColumnIndex)

      if (searchColumnIndex != -1) {
        searchCriteria.value.splice(searchColumnIndex, 1, val)
      } else {
        searchCriteria.value.push(val)
      }
    })
    watch(criteriaFilters, val => {
      if (criteriaQuery.value == null) {
        searchCriteria.value = val
      } else {
        searchCriteria.value = [criteriaQuery.value].concat(val)
      }
    })

    watch(searchCriteria, val => {
      // console.log('searchCriteria change', val)
      currentPage.value = 1
      emit('fetch', {
        page: 1,
        perPage: perPage.value,
        sortColumn: sortColumn.value,
        sortOrder: sortOrder.value,
        searchCriteria: val
      })
    })

    watch(selectedView, val => {
      emit('update:openViewWindow', false)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const selectFilter = (newFilter) => {
      // console.log(newFilter)

      if (newFilter.key == '_date_custom_filter') {
        let newFilterCloned = JSON.parse(JSON.stringify(newFilter))
        newFilterCloned.text = newFilter.text + ' : du ' + moment(newFilter.searchCriteria[0].search_text_0).format('L') + ' au ' + moment(newFilter.searchCriteria[0].search_text_1).format('L')

        if (!selectedFilters.value.some(f => f.key == newFilterCloned.key)) {
          selectedFilters.value.push(newFilterCloned)
        }
      } else if (!('children' in newFilter)) {
        if (!selectedFilters.value.some(f => f.key == newFilter.key)) {
          selectedFilters.value.push(newFilter)
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    selectedView.value = props.itemView

    return {
      // Components

      // Data
      selectedView,
      currentPage,
      perPage,
      sortColumn,
      sortOrder,
      query,
      selectedFilters,

      // Computed

      // Methods
      selectFilter,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}