<template>
  <b-row>
    <!--    Title-->
    <b-col md="12">
      <field-input
          name="title"
          rules="required"
          :model.sync="ticket.title"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Type-->
    <b-col md="4">
      <field-select
          name="type"
          :model.sync="ticket.type"
          :options="ticketTypes"
          label="value"
          reduce="label"
          :isRequired="true"
          :clearable="false"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Priority-->
    <b-col md="4">
      <field-select
          name="priority"
          :model.sync="ticket.priority"
          :options="ticketPriorities"
          label="value"
          reduce="label"
          :clearable="false"
          :isRequired="true"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Module-->
    <b-col md="4">
      <field-select
          name="module"
          :model.sync="ticket.module"
          :options="ticketModules"
          label="value"
          reduce="label"
          :disabled="readOnly"
      />
    </b-col>

    <!--    Files-->
    <b-col md="12">
      <b-row>
        <b-col
            v-if="!readOnly"
            md="6"
        >
          <field-file
              :singleFile.sync="newFile"
              documentType="ticket"
              :resetAfterUploaded="true"
          />
        </b-col>

        <b-col :md="readOnly?12:6">
          <b-card
              class="card-transaction"
              no-body
          >

            <b-card-body>
              <content-document
                  v-for="(file, index) in ticket.files"
                  :document="file"
                  :isParentMethodPriority="true"
                  :is-deletable="!readOnly"
                  @click="download(file)"
                  @download="download(file)"
                  @delete="deleteFile(index)"
              />

            </b-card-body>
          </b-card>

        </b-col>

      </b-row>
    </b-col>

    <!--    Content-->
    <b-col md="12">

      <field-textarea
          :model.sync="ticket.content"
          name="content"
          placeholder="content"
          :isRequired="true"
          :disabled="readOnly"
      />
    </b-col>


  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldInput from '../input/Input'
import FieldSelect from '../input/Select'
import FieldTextarea from '../input/Textarea'
import ContentDocument from '../card/components/DocumentContent'
import FieldFile from '../input/File'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldTextarea,
    ContentDocument,
    FieldFile,
    vSelect
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const ticketTypes = ref([
      {
        label: 'Issue',
        value: i18n.t('Issue')
      },
      {
        label: 'Question',
        value: i18n.t('Question')
      }
    ])
    const ticketModules = ref([
      {
        label: 'Home',
        value: i18n.t('Home')
      },
      {
        label: 'Contacts',
        value: i18n.t('Contacts')
      },
      {
        label: 'Indicators',
        value: i18n.t('Indicators')
      },
      {
        label: 'Orders',
        value: i18n.t('Orders')
      },
      {
        label: 'Incoming invoices',
        value: i18n.t('Incoming invoices')
      },
      {
        label: 'Workflow',
        value: i18n.t('Workflow')
      },
      {
        label: 'Offers',
        value: i18n.t('Offers')
      },
      {
        label: 'Outgoing invoices',
        value: i18n.t('OutgoingInvoices')
      },
      {
        label: 'Banking flows',
        value: i18n.t('BankingFlows')
      },
      {
        label: 'Customer receivable',
        value: i18n.t('CustomerReceivable')
      },
      {
        label: 'Supplier receivable',
        value: i18n.t('SupplierReceivable')
      },
      {
        label: 'Employees',
        value: i18n.t('Employees')
      },
      {
        label: 'Salaries',
        value: i18n.t('Salaries')
      },
      {
        label: 'Holidays',
        value: i18n.t('Holidays')
      },
      {
        label: 'Social security contributions',
        value: i18n.t('SocialSecurityContributions')
      },
    ])
    const ticketPriorities = ref([
      {
        label: 'Normal',
        value: i18n.t('NormalE')
      },
      {
        label: 'High',
        value: i18n.t('High')
      },
      {
        label: 'Urgent',
        value: i18n.t('Urgent')
      }
    ])
    const newFile = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const readOnly = computed(() => {
      if (props.ticket.status == 'New') {
        return false
      } else {
        return true
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newFile, (val) => {
      if (val != null) {
        props.ticket.files.splice(props.ticket.files.length, 0, val)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const deleteFile = (index) => {
      props.ticket.files.splice(index, 1)
    }

    const download = (file) => {
      console.log('0')
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = file.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      ticketTypes,
      ticketModules,
      ticketPriorities,
      newFile,

      // Computed
      readOnly,

      // Methods
      deleteFile,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>